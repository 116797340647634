{
  /* <p>Число: <span data-animation-counter="150">0</span></p>
<p>Еще одно: <span data-animation-counter="500">0</span></p> */
}

export const animateCounterOnScroll = () => {
  const counters = document.querySelectorAll("[data-animation-counter]");
  if (!counters.length) return;

  function startCounting(counter) {
    const targetNumber = parseInt(
      counter.getAttribute("data-animation-counter"),
      10
    );
    let currentNumber = 0;
    const speed = Math.ceil(targetNumber / 2000); // Скорость увеличения

    function updateCounter() {
      currentNumber += speed;
      if (currentNumber > targetNumber) currentNumber = targetNumber;
      counter.textContent = currentNumber;

      if (currentNumber < targetNumber) {
        requestAnimationFrame(updateCounter);
      }
    }

    updateCounter();
  }

  function handleScroll() {
    counters.forEach((counter) => {
      const rect = counter.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (
        rect.top < windowHeight / 2 &&
        rect.bottom > windowHeight / 2 &&
        !counter.dataset.animated
      ) {
        counter.dataset.animated = "true"; // Запускаем анимацию только один раз
        startCounting(counter);
      }
    });
  }

  window.addEventListener("scroll", handleScroll);
  handleScroll(); // Проверяем при загрузке
};
