export const swiperInit = () => {
  const feedbackSection = document.querySelector(".feedback");

  if (feedbackSection) {
    const feedback = new Swiper(".feedbackSwiper", {
      effect: "cards",
      grabCursor: true,
      navigation: {
        nextEl: ".feedback__next",
        prevEl: ".feedback__prev",
      },
      on: {
        init: function () {
          let maxHeight = 0;
          feedbackSection.querySelectorAll(".swiper-slide").forEach((slide) => {
            maxHeight = Math.max(maxHeight, slide.offsetHeight);
          });
          feedbackSection.querySelector(".swiper-wrapper").style.height =
            maxHeight + "px";
        },
      },

      allowTouchMove: true, // Разрешает свайпы на мобильных
    });
  }

  const photoGallerySection = document.querySelector(".photo-gallery");

  if (photoGallerySection) {
    var photoGallery1 = new Swiper(".photoGallery1", {
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var photoGallery2 = new Swiper(".photoGallery2", {
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: photoGallery1,
      },
    });
  }
};
