"use strict";

// Connecting vendors (plugins)
import "./_vendor";

// Functions
import {
  mobileCheck,
  burger,
  accordion,
  animateCounterOnScroll,
  swiperInit,
} from "./functions/";

// Components
// import { formValidation } from "./components/";

window.addEventListener("DOMContentLoaded", () => {
  mobileCheck();
  burger();
  animateCounterOnScroll();
  accordion(
    ".faq__list",
    "faq__title",
    ".faq__item",
    "faq__item--active",
    false
  );
  swiperInit();
});
